*{box-sizing: border-box;outline: none;}
html,h1,h2,h3,h4,h5,h6,ul,ol,li,p{padding:0px;margin:0px;}
a,span,label{text-decoration: none;}
a:hover{color:#ffcc00}
img{max-width:100%;height:auto;    vertical-align: middle;}
button{border: 0px;background: 0px;display: inline-block;cursor: pointer;}

table,tr,td{border-collapse:collapse;}
table,tr,td{border-collapse:collapse;}
nav:not(.mm-menu){display:none}

body{      position: relative;font-family: "MRR", "Helvetica Neue", Helvetica, sans-serif;font-size: 14px;line-height: 1.5;margin: 0px auto;}
.wrapper{width: 1200px;margin:0px auto;}
.transition(@a){
	transition: @a;
	-moz-transition: @a;
	-webkit-transition: @a;
	-o-transition: @a;
}

.box-shadow(@a){
	box-shadow: @a;
	-moz-box-shadow: @a;
	-webkit-box-shadow: @a;
}

.transform(@a){
	-webkit-transform: @a;
	-moz-transform:    @a;
	-ms-transform:     @a;
	-o-transform:      @a;
	transform:   @a;
}
.animation(@name,@translate,@delay){
	-webkit-animation: @name @translate ease-in-out both;
	-ms-animation: @name @translate ease-in-out both;
	-o-animation: @name @translate ease-in-out both;
	animation: @name @translate ease-in-out both;
	-webkit-animation-delay:@delay;
	-ms-animation-delay:@delay;
	-o-animation-delay:@delay;
}
.hover(@a,@b,@c){
	background: @a;
	color: @b;
	.transition(@c);

}

.border-image(@url,@w,@r){
	border: @w solid transparent;
	-webkit-border-image: url(@url) @r stretch; 
	-o-border-image: url(@url) @r stretch;
	border-image: url(@url) @r stretch;
}

.border-image-source(@url,@w,@c,@r){
	border: @w solid transparent;
	border-image-source:url(@url);
	border-image-slice: @c @r;
}


.animation1(@name,@translate,@delay){
	-webkit-animation: @name @translate ease-in-out both;
	-ms-animation: @name @translate ease-in-out both;
	-o-animation: @name @translate ease-in-out both;
	animation: @name @translate ease-in-out both;
	-webkit-animation-delay:@delay;
	-ms-animation-delay:@delay;
	-o-animation-delay:@delay;

}
@keyframes nhay{
	0%{.transform(scale(1));}
	25%{.transform(scale(1.3));}
	50%{.transform(scale(1.1));}
	75%{.transform(scale(1.2));}
	100%{.transform(scale(1));}
}

.atht(@w,@h,@bg){content:'';position: absolute;width: @w;height:@h;background:url(@bg);left: -@w;top:0px;}		
.placeholder(@font-family,@color,@font-size,@font-weight:normal,@case:''){
	&::-webkit-input-placeholder{
		.font("@{font-family}",@color,@font-size,@font-weight);
		& when not(@case = ''){
			text-transform:@case;
		}
	}
	&::-moz-placeholder{
		.font("@{font-family}",@color,@font-size,@font-weight);
		& when not(@case = ''){
			text-transform:@case;
		}
	}
	&::-ms-input-placeholder{
		.font("@{font-family}",@color,@font-size,@font-weight);
		& when not(@case = ''){
			text-transform:@case;
		}
	}
	&::-moz-placeholder {
		.font("@{font-family}",@color,@font-size,@font-weight);
		& when not(@case = ''){
			text-transform:@case;
		}
	}
}

.srollbar(@bg){
	&::-webkit-scrollbar {
		width: 7px;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-thumb {
		background-color:@bg;
		border-radius: 20px;
		outline: 1px solid @bg;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px transparent;
		border-radius: 20px;
	}
}


.font(@font,@color,@size,@weight:'',@case:''){
	font-family: @font;
	color: @color;
	font-size: @size;
	& when not (@weight = ''){
		font-weight: @weight;
	}
	& when not(@case = ''){
		text-transform:@case;
	}
}

.clear{clear:both;}
#top{ width: 50px;height: 50px;background: #fff url(../images/top.png) center;position: fixed;right: 30px;bottom: 15%;cursor: pointer;display: none;z-index: 100;border-radius: 50%;    border: 5px solid #555;}
#breadcrumbs{position:relative;
	ul{position:relative;margin:0;border-radius:0!important;padding: 5px 0px;text-align: right;    border-bottom: 1px solid #f2f2f2;
		> li {display: inline-block;position: relative;margin-right: 10px;
			&:before {content: '\f105';position: absolute;font-family: FontAwesome;left: -9px;font-size: 12px;top: 2px;}
			&:first-child:before{display: none;}
			a{font-size: 13px;color:#000;}
		}
	}
}
.visit_hidden{height:0;width:0;overflow:hidden;margin:0;padding:0}
.paging{
	margin:auto;width:auto;text-align:center;margin:10px 0;
	ul{text-align:center;color:#000;
		li{  margin-bottom: 10px;
		    display: inline;
		    padding: 0 3px;
		    display: inline-block;
		    border-radius: 2px;
		    text-align: center;
		    vertical-align: top;

			a{    display: inline-block;
			    border: 1px solid #d9d9d9;
			    text-decoration: none;
			    color: #000;
			    background: linear-gradient(to bottom,#fff,#c4c4c4);
			    border-radius: 30px;
			    width: 25px;
			    height: 25px;
				&:hover{background:#d00;color:#fff}
			}
			.current{background: #d00;color:#fff}
		}
	}
}

.lozad{opacity:0;visibility: hidden;}
.fade {animation-name: fade;animation-duration: 0.5s;opacity: 1;visibility: visible;}
@keyframes fade {from {opacity: 0;visibility: hidden;}to {opacity: 1;;visibility: visible;}}

.flex-sticky{display: flex;align-items:flex-start;justify-content: flex-start;}
.sticky-left{position: sticky;    top: 97px;}

// tab
.tab__content>div{display: none;}
.act-ct{display: block !important}
.act{display: block !important;} 
// ////////////////////////////////////////////////////////////////
// header
.fix{   background: #f5f5f5;color: #000;
	height: 40px;
	background-size: cover;
	span{    display: inline-block;
    line-height: 40px;
    height: 40px;
    font-size: 13px;}
	&__address{      margin-right: 40px;
		float: left;
		text-align: center;
		padding-left:30px;background: url(images/h1.png) no-repeat 0px;font-family: OPR;
	}
	&__hotline{padding-left: 25px; background:url(../images/h2.png) no-repeat 0px; float: left;margin-right: 15px;}
	&__email{padding-left: 25px; background:url(../images/h2.png) no-repeat 0px ;  float: right; }
	&__slogan{float:left;  font-family: OPB;}
	&__website{margin-right: 45px; padding-left: 25px; background:url(../images/h3.png) no-repeat 0px ; float:left;  }
	&__flex{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.header{    position: relative;
	&__logo{ 
		img{}
	}
	&__flex{display: flex;align-items: center;justify-content: space-between;    max-width: 1010px;}
	&__ngonngu{
		ul{
			display: flex;justify-content: flex-end;
			li{display: inline-block;padding:0 5px;}
		}
	} 
	&__banner{text-align: left;position: relative;z-index: 999;}
	&__hotline{    display: inline-block;
    float: right;
    width: 248px;
    height: 55px;
    background: url(images/header__hl.png) no-repeat 0px;
    padding-left: 50px;
    font-family: OPB;
    padding-top: 4px;
    color: #000;
    p{
    	&:nth-child(2){  font-size: 20px;
    color: #d00;
    line-height: 20px;
    margin-bottom: 10px;
}
    }
		// &:after{content:'';position: absolute;width: 1px;height: 10px;border-right: 1px solid #fff;right: -10px;
		// top: 15px;}
		span{color:#d00;font-size: 16px;}
	}
	&__email{color: #ffcc00; display:inline-block;float: left ; padding-left: 30px;background: url(../images/h-em.png) no-repeat 0px;text-align: left;line-height: 37px;}
	&__address{position: relative;    margin-top: 6px;display: inline-block;}
	&__sohl{          background: url(../images/header__ich.png) no-repeat 0px;
		padding: 12px 20px 12px 50px;
		display: inline-block;
		text-align: left;
		p{   
			font-family: RB;
			color: #ed1c24;
			font-size: 18px;
			padding-top: 13px;
			width: 216px;
			// &:nth-child(1){
			// 	font-size: 15px;
			// 	font-family: RR;text-transform:uppercase;
			// 	// color: #d00;text-shadow: -2px -2px #fff, 2px 2px #fff,-2px 2px #fff,2px -2px #fff,2px 0px #fff,0px 2px #fff;
			// }
			// &:nth-child(2){    font-family: UTMI;
			// 	color: #ed1c24;
			// 	font-size: 22px;    line-height: 22px;
			// }
			// 
		}
	}

	&__sohl1{background: url(../images/header__ich1.png) no-repeat;padding:0px 10px 10px 60px;
		p{color: #000;font-size: 15px;
			&:nth-child(2){font-family: RB;
				color: #1c78c7;line-height:25px;
			}
		}
	}
	&__mxh, &__company{padding: 10px;}

	&.cus-header{    position: relative;}
}

// fix menu top
.fix_menutop{position: fixed !important;z-index: 99;top: 0px;left: 0px;right: 0px;margin: auto;       background: #fff;
	.header__logo{
		// img{max-width: 85px;}
	}
	.box-search{margin:0px;}
	.menu > ul > li >a{}
	.menu ul li:hover > ul,.menu ul li > ul{top: 45px;}
	.menu > ul > li > a{}
	.boxdetail{padding:0px;}
	// .search{top:49px !important;}
}

.boxdetail{
	padding-bottom: 40px;
}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// menu 
// 

   
.bg-menu {   }
.menu{    display: flex;
    align-items: center;
      justify-content: space-between;
    background: #ce2828;
	> ul{ display: flex;align-items: center;position: relative;
		>li { display:inline-block;text-align:center;position:relative;list-style:none;float:left;
			>a {     position: relative;
			    line-height: 45px;
			    padding: 0px 35px;
			    display: inline-block;
			    cursor: pointer;
			    color: #fff;
			    font-family: OPB;
			    font-size: 15px;
			    text-transform: uppercase;
				    // border-right: 1px solid #77b100;
				// background:url(images/mnr.png) no-repeat right center;

					&:after{
						content:url(images/icmn.png);
						position:absolute;top: 5px;right: -2px;}
					&:hover{ color:#ff0;}
					span{position:absolute;width:100%;top:0px;height:100%;left:0px;z-index:0;visibility:hidden;background-size: 100% 100%;}
				}
				&:before{
					// content: '';
					position: absolute;
					width: 0%;
					height: 6px;
					background: #d00;
					top: 0px;
					left: 0px;
					right: 0px;
					margin: auto;

					.transition(all ease 0.5s);}
					&:hover:before{
						width: 100%  !important;
						display: block !important;
						.transition(all ease 0.5s);
					}
					
					&:last-child >a:after{display: none;}
					&:last-child >a{border:0px;}
				// &:first-child >a{background: url(images/home.png) no-repeat 0px;    padding-left: 40px;}
			// &:hover >a{    background:#ad0101;}
			&:hover span{visibility:visible !important;}
			&:hover > ul{visibility:visible;opacity:1;}
			span{position: absolute;width:100%;height: 100%;left: 0px;top: 0px;background: #f00;visibility: hidden;transform: skew(-33deg);}
			
		}
		ul{top:45px;left:0px;  position:absolute;visibility:hidden;width:200px;opacity:0;.transition(all ease 0.5s);z-index: 9999999;
			li { 
				background:#ce2828;
				box-shadow: inset 0px 1px #ffffff70; 
				// box-shadow: inset 0px 1px #f2f2f2;
				width: 200px;
				text-align: left;
				box-sizing: border-box;
				display: block; 
				position: relative !important;

				a{color: #fff;display:inline-block;width:100%;padding:5px 10px;font-size: 13px;}

				&:hover >ul{top: 0 !important;opacity:1;visibility:visible;}
			}

			ul{ visibility: hidden;width: 200px;margin-left: 198px;opacity: 0;left: 2px;top: 0px;position: absolute;
				
			}
		}
		
		
		
	} 
	
	&__active:before{width:100% !important}
	&__active{ color: #ff0 !important;}
	.ngonngu ul li{display: inline-block;float: right;margin-right: 5px;}


}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// search 
.box-search{       position: relative;
	display: inline-block;
	float: right;
	z-index: 99;
	text-align: right;
	padding-left: 10px;      border-left: 1px solid #d9d9d9;
}
.search{   display: inline-block;
	&.rel{position: relative;float: right;margin-right: 30px;}
	&.alb{position: absolute;right:0px;top:60px;display: none;}
	&__title { font-family: UTMHelvetIns;color: #fff; font-size: 16px;display: inline-block;float: left;margin-right: 10px;line-height: 30px;text-transform: uppercase;}
	// select{      border: 1px solid #d5d5d5;border-left: 0px;display: inline-block;float: left;height: 37px;width: 105px;color: #555;font-family: MLR;font-size: 12px;}
	&__txt{       color: #fff;
    height: 32px !important;
    padding: 10px;
    padding-right: 55px;
    padding-left: 15px;
    background: #a22020;
    width: 235px;
    border: 0px;
    box-shadow: inset 1px 1px 0px #55555573;
    border-radius: 20px;
		.placeholder('Arial',#fff,'12px','','');

	}
	&__btn{      border: 0!important;
		width: 45px;
		height: 100%;
		background: url(../images/srch1.png) no-repeat center;
		color: #fff;
		text-transform: uppercase;
		position: absolute;top: 0px;right:0px;
		cursor:pointer;
	}
}


// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////

// product
.product{ 
	&__item{position:relative;margin-bottom: 15px;
		// &:hover .product__img{border:1px solid #fff !important;}
		&:hover .product__ab{background:#00000094;height: 86%;.transition(all ease 0.5s);
		}
	}
	&__item:hover &__border{box-shadow:0px 0px 15px 0px #00000057;}
	&__item:hover &__img img{.transition(all ease 0.5s); .transform(scale(1.1))}
	// .dathang{ text-align: center;margin-top: 10px;
	// 	a{padding:5px 20px;display: inline-block;border:1px solid #88B44E;border-radius: 5px;color: #88B44E;font-family: MLB;}
	// } 
	// &:hover .cart{bottom: 5px !important;.transition(all ease 0.5s);}
	// &:hover .dathang a{color: #fff;background: #88B44E;}
	// &:hover img{transform: scale(1.1);.transition(all ease 0.5s);}
	// .bg-prd{padding: 10px;background: #ededed;}

	&__bg{position: absolute;
		width: 28px;
		height: 28px;
		background: url(images/gocprd.png);
		top: 0px;
		left: 0px;
		z-index: 9;
	}
	
	&:hover &__border{}
	&__border{
		// padding: 5px;
		// .border-image("../images/brprd.png",5px,5);
		// .border-image-source("../images/brprd.png",5px,5,5)
		// background: url(images/brprd.png) no-repeat;
		// background-size: cover;
	}
	&__date{
		font-size: 13px;
		font-family: OPR;
		color: #828282;
		padding: 10px 0;
	}
	&__image{
		img{  border-radius: 20px;}
	}
	&__news {
		position: absolute;
		width: 45px;
		height: 45px;
		background: url(images/new.png) no-repeat center center;
		right: -5px;
		top: -5px;
		z-index: 999;
		background-size: 45px;
		.transform(rotate(90deg));
	} 
	&__img{overflow: hidden;position: relative;height: 0px;border: 1px solid #e6e6e6;
		padding-bottom: 85%;}
		&__img1{overflow: hidden;position: relative;height: 0px;
			padding-bottom: 109%;border-radius: 20px;
			img{  border-radius: 20px;}
		}

		&__content{     padding: 10px;
    background: #f2f2f2;}
		&__contentid{ text-align:center;padding: 45px;}
		&__name{text-align: center;
			a{     
				       color: #000;
    font-family: RR;
			}
		}
		&__price{display: flex;align-items: center;  justify-content: center;}
		&__giaban{  display: inline-block;
    color: #000;
    padding: 5px 0;
			span{  color: #d00;
				line-height: 20px;
				display: inline-block;
				font-family: RB;
			}
		}
		&__giacu{font-family: RR;text-decoration: line-through;color: #7f7f7f;}
		&__des{    padding: 10px 0;
			font-family: OPR;
			line-height: 2.0;
		}

		&__ab{ position: absolute;
			width: 86%;
			height: 20%;
			bottom: 7%;.transition(all ease 0.5s);
			left: 7%;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius:4px;
		}
		&__view{    text-align: center;width: 40%;
			a{  
				font-family: OPR;
				color: #fff;
				line-height: 30px;
				display: inline-block;
				position: relative;
				font-size: 13px;
				padding: 0 20px;
				background: #018e01;
				border-radius: 4px;
			// &:after{content:'>>';position: absolute;right: 10px;}
		}
	}
	&__flex{
		display: flex;
		background: #fff;
		margin: 15px 0;border-radius: 20px;
	}
	&__box{
		max-width: 820px;
		margin: 0px auto;
		padding: 20px 0;
	}
	&__masp{    text-align: center;width: 50%;color:#616161;
		span{  
			color:#a7a7a7;
		}
	}
	&__cart{        text-align: center;
		display: flex;
		justify-content: space-between;
		align-items: center;
		a{ 
			display: inline-block;
			color: #7ac3f4;
			font-size: 13px;
			border-radius: 20px;
			font-family: OPB;
			text-transform: uppercase;
			&:nth-child(2){
				background: url(images/brv.png) no-repeat;
				padding: 13px 0px;
				width: 125px;
			}
		}
		img{width: auto !important;display: inline-block !important;}
	}
	&__zalo{display: inline-block;width: 100%;font-family: RR;font-size:15px;    margin-top: 5px;
		a{color: #ed1c24;font-family: RB;font-size: 16px;}
	}


	&.box-top{padding:30px 0;}
	&.box-top &__item{margin-top: 10px;}
}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////

// menu tab index
.menulist{max-width: 1000px;margin:0 auto 25px;justify-content: center;display: flex;
	a{
		padding: 8px 10px;
		color: #000;
		border: 1px solid #000;
		background: #fff;
		text-transform: uppercase;
		text-align: center;    display: inline-block;    margin: 3px;    border-radius: 5px;
	}
	// &__act {    
	// 	border: 1px solid #00438f !important;
	// 	color: #fff !important;
	// 	background: #00438f !important;
	// }

	& .act {    
		border: 1px solid #eb1111 !important;
		color: #fff !important;
		background: #d00;
	}
}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// 
// product detail
.prddetail{ 
	&__content{   
    padding: 10px;}
	&__name{}
	&__title{  
	    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    border-bottom: 1px dotted #c4c4c4b5;
    padding: 7px 0;
	}
	&__li{
		    list-style: none;
    text-align: justify;
    padding: 5px ;
    border-bottom: 1px dotted #c4c4c4b5;
	}
	&__li:nth-child(even) {
    background: #f2f2f28c;
}
&__lib .button {
    background:linear-gradient(to bottom,#f2f2f2,#e4e4e4);
    color: #000;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    font-family: OPB;
    border-radius: 5px;
    margin-right: 10px;
}
a:hover {
    color: #d00;
}
&__lib .button1 {
    background:linear-gradient(to bottom,#f2f2f2,#e4e4e4);
    color: #000;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    font-family: OPB;
    border-radius: 5px;
}
	&__li--masp{   }
	&__li--dis{}
	&__li--giagoc{text-decoration: line-through;color: #888;}
	&__li--giaban{color: #d02f03;font-size: 16px;}
	&__li--contact{ 
		font-family: UTMI;
		font-size: 15px;
		color: #d00;
	}
	&__li--label {
		text-align: left;
		position: relative;
		width: 155px;
		display: inline-block;
	}
	&__li--cs{ 
		a {
			line-height: 20px;
			color: #000;
			width: 100%;
			display: inline-block;
			margin-bottom: 5px;
			font-size: 13px;
			background: #f2f2f2;
			padding: 7px;

		}
	}
	&__noidung {
		padding: 10px;
		background: #f2f2f2;
		margin-bottom: 20px;
	}
	&__li--tags{list-style:none;margin:0;overflow:hidden;padding:0px;
		li{ float:left;display:inline-block;
			a{    display: inline-block;
				position: relative;
				text-decoration: none;
				float: left;
				color: #555;
				font-size: 13px;
				padding: 5px 10px;
				border: 1px solid #d9d9d9;background: #f2f2f2;
				margin-bottom: 5px;
				margin-right: 5px;
			}
			a:hover{text-decoration: underline;}
			&:nth-child(1){margin-right: 10px;line-height: 28px;}
		}
	}
}
.item_box{display: none;}

// ul{
// li{ display: inline-block;width: auto;margin-right: 10px;
// a{

// }
.flex-cart{
	display: flex;
}
.cart_chitiet{  display: inline-block;
	padding: 12px 20px 10px 40px;
	color: #fff;
	font-family: MSB;
	cursor: pointer;
	text-decoration: none !important;
	text-transform: uppercase;
	position: relative;background: #ae0101;
	i{    color: #fff;
		font-size: 18px;
		position: absolute;
		left: 15px;
		top: 12px;}
		&:hover ,&:hover i{background: #000;.transition(all ease 0.5s);}
	}
// }
// }
// 
// 

.prdlist{ padding:30px 0;background:url(../images/top-prl.png);
	&__item{ overflow: hidden;
		position: relative;
		border-radius: 15px;
		&:hover img{
			.transition(all ease 0.5s);.transform(scale(1.1));
		}
	}
	&__img{
		border-radius: 15px;overflow: hidden;
		img{border-radius: 15px;.transition(all ease 0.5s);.transform(scale(1));}
	}
	&__name{
		position: absolute;width: 100%;bottom:0px;left: 0px;
		text-align: center;background:#0000005c;
		a{
			display: inline-block;padding:7px 0;font-family: OPB;color: #fff;
		}
	}
}


// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Chính sách

.chinhsach{   padding: 25px 0px;
    background: url(images/brvct.png) no-repeat;
    background-size: cover;
	&__item{text-align: center;}
	&__img{img{width: auto !important; display: inline-block !important;margin-bottom: 5px; }}
	&__name{font-size:15px;text-transform: uppercase;padding: 5px 0;
		a{    color: #d00;
    font-family: RB;
    font-size: 16px;}
	}
	&__des{
		border-top: 0px;    text-align: center;
		font-family: OPR;line-height:1.8;color: #555;
	}
}
// .chinhsach{padding: 30px 35px;
//     background: url(images/brcs.png) no-repeat;
//     background-size: cover;
//     border: 3px solid #fff;
//     position: relative;
//     z-index: 99;
//     margin-bottom: 20px;
// 	&__item{text-align: center;display: flex;align-items: center;}
// 	&__content{text-align: left;}
// 	&__img{margin-bottom: 10px;width: 120px}
// 	&__name{font-family:OPB;text-transform: uppercase;margin-bottom: 5px;
// 		a{color:#000;}
// 	}
// 	&__des{
// 		border-top: 0px;
// 		font-family: RR;
// 	}
// }

// // ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Vì sao chọn chúng tôi
// .visao{    padding: 30px 0;
// 	background: url(../images/brvs.png) no-repeat;
// 	background-size: cover;
// 	min-height: 500px;
// 	&__item{    text-align: center;
// 		background: #fff;
// 		height: 0px;
// 		padding-bottom: 100%;
// 		position: relative;border-radius: 50%;
// 	}
// 	&__bd{
// 		display: flex;align-items:center;justify-content: center;position:absolute;width: 100%;height: 100%;text-align: center;left:0px;top:0px;
// 	}
// 	&__img{margin-bottom: 10px;}
// 	&__name{font-family:OPB;text-transform: uppercase;margin-bottom: 5px;}
// 	&__des{padding:0px 20px 20px 20px;border:1px solid #d9d9d9; border-top:0px; font-family: RR;}
// }

.visao{   background:url(images/brvs.png) no-repeat; background-size: cover;
	&__item{    text-align: center;
		position: relative;display: flex;align-items: flex-start;
	}
	div{ 
		span{ position: absolute;font-family: OPR;font-style: italic;color:#ffcf6b;
			&:nth-child(1){    top: 60px;left: 200px;}
			&:nth-child(2){bottom: 10px;left: 295px;}
			&:nth-child(3){top: 60px;right: 200px;}
			&:nth-child(4){bottom: 10px;right: 295px;}
		}
	}
	&__img{width:25%;
	}
	&__name{padding:5px 0;    max-width: 175px;
		a{    font-family: RB;
			text-transform: uppercase;
			margin-bottom: 5px;
			color: #fff;
			font-size: 15px;
			&:hover{color: #ff0;}
		}
	}
	&__des{
		font-family: RR;
		font-size: 13px;
		color: #fff;
	}
	&__content{text-align: left;width: 75%;    padding-top: 15px;}
}

.hotro{   background:#f0f0f0;
	&__item{    text-align: center;
		position: relative;display: flex;align-items: flex-start;
	}
	&__img{width:25%;
	}
	&__name{padding:5px 0;
		a{    font-family: RM;
			text-transform: uppercase;
			margin-bottom: 5px;
			color: #555;
			font-size: 15px;
		}
	}
	&__des{
		font-family: RR;
		font-size: 13px;
		color: #555;
		line-height: 1.8;
	}
	&__content{text-align: left;width: 75%; }
}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Giới thiệu
.gioithieu{     position: relative;
    color: #000;
    background-size: cover;    min-height: 560px;

	&__img{ text-align: center;
    position: relative;max-width: 760px;margin:0px auto;

		img{    z-index: 1;
			position: relative;
		}
		&:after{
			// content: '';
			position: absolute;
			z-index: 0;
			width: 100%;
			height: 120px;
			right: 0px;
			bottom: -60px;
			border: 7px solid #fff;
			box-sizing: border-box;
		}
		p{
			&:nth-child(1){
				position: relative;
				top: 0px;
				right: 112px;
			}
			&:nth-child(2){
				position: absolute;
				z-index: 99;
				bottom: -40%;
				right: 0%;
				border: 7px solid #fff;

			}
		}
	}
	&__list{padding-top:30px}
	&__tieude{
		     font-family: UBC;
    font-size: 55px;
    color: #000;
        line-height: 65px;
	}
	&__title{       
	       color: #6c2609;
    font-family: SA;
    padding-bottom: 10px;
    background: url(images/ictd.png) no-repeat bottom center;
    margin-bottom: 10px;
    font-size: 66px;
		p{
    		font-family: OPR;
    padding: 7px 0;
    font-size: 14px;
    text-transform: none;
    color: #555;
		}
	}
	.link-gt{
		span{
			font-family: OPB;
		}
		p{text-transform: uppercase;
			a{color: #000; text-transform: uppercase;}
		}
	}
	&__content{margin-bottom: 20px;position: relative;}
	&__des{     line-height: 1.8;
    margin-bottom: 30px;
    color: #4a4a4a;
    font-family: SSU;
    margin: 0px auto 15px;
    font-size: 16px;
     .text-right{
     	font-style: italic;border-right: 2px solid #3e3e3e;
     }
	}
	&__view{margin-bottom: 10px;
		a{ font-family: OPR;
    color: #efa601;
    display: inline-block;
    text-align: left;
    width: 195px;
    padding-left: 45px;
    line-height: 60px;
    background: url(images/v.png);
		}
	}

	.gioithieu__dk{
		background: #c57036 url(../images/icdk.png) no-repeat 3px;
		padding: 7px 10px 6px 30px;
		margin-left: 10px;cursor: pointer;
		color: #fff;
		text-transform: uppercase;
		font-family: RM;
	}

}

.bgtop{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5px;
	img{margin:0 5px;}
}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Tin tức
.news{
	&__item{     position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 5px;display: flex;align-items: flex-start;
		padding: 5px;   border:1px solid #f2f2f2;background: #f2f2f2;
	}
	&__img{margin-right:10px;opacity:1;transition: all ease 0.5s;width: 30%;
		img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
		&:hover{opacity:0.8;transition: all ease 0.5s;}
	}
	&__content{text-align:left;width: 70%;}
	&__name{
		a{   color: #000;
			font-family: RB;text-transform: uppercase;
			outline: none !important;
		}
	}
	&__des{color:#000;line-height: 1.6; font-size: 13px;font-family: RR;
	}
	&__view{    text-align: left;
		a{  
			font-family: RR;
			margin-top: 10px;font-size: 12px;
			line-height: 30px;
			display: inline-block;
			position: relative;
			padding: 0 20px;border:1px solid #d9d9d9;
			background: #f2f2f2;
			color: #000;
		}
	}

}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Bất đọng sản
.bds{ padding: 30px 0; 
	&__item{  position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 10px;
		border: 1px solid #e3e3e3;
		border-bottom: 4px solid #e3e3e3;padding:8px;}
		&__img{margin-bottom: 7px; background: #fff;opacity:1;.transition(all ease 0.5s);
			img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
			&:hover{opacity:0.8;.transition(all ease 0.5s);}
		}
		&__content{text-align:center;}
		&__name{a{   color: #000;
			font-family: MLB;    font-size: 13px;
			display: inline-block;
			width: 100%;
			outline: none !important;
			text-transform: uppercase;
		}
	}

	&__des{color:#000;line-height: 1.6;text-align: center; font-family: MLI;
		p{  
			&:nth-child(1){
				span{color:#205bbc;font-family: MLB;}
			}
			&:nth-child(2){
				span{color:#be0000;font-family: MLB;}
			}
			&:nth-child(3){
				span{color:#dea433;font-family: MLB;}
			}
		}
	}

	&__info{margin-top: 5px;color:#000;line-height: 1.6;text-align: justify; font-size: 13px;
		p{ display: inline-block;
			float: left;
			margin-right: 10px;
			&:nth-child(1){
				background:url(../images/new1.png) no-repeat 0px;
			}
			&:nth-child(2){
				background:url(../images/new2.png) no-repeat 0px;
				span{color:#d00;}
			}
			&:nth-child(3){
				background:url(../images/new3.png) no-repeat 0px;
				span{float: right;}
			}
		}
		label{padding-left: 20px;font-family: RB;    display: inline-block;}
	}

}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Bài viết detail
.dadetail{ 
	&__content{}
	&__name{}
	&__title{      padding: 8px;
		font-size: 20px;
		font-weight: normal;
		background: #f2f2f2;
		margin-bottom: 10px;
	}
	&__li{
		list-style: none;
		text-align: justify;
	}
	&__li--des{}
	&__li--contact{ 
		font-family: QB;
		font-size: 15px;
		color: #d00;
		margin-top: 10px;
	}
	&__li--label {
		text-align: left;
		position: relative;
		width: 155px;
		display: inline-block;
	}
	&__li--cs{ 
		a {
			line-height: 20px;
			color: #000;
			background: #f2f2f2;
			width: 100%;
			padding: 7px;display: inline-block;
			margin-bottom: 5px;

		}
	}
	&__noidung {
		padding: 10px;
		background: #f2f2f2;
		margin: 20px 0;
	}

	&__des{color:#000;line-height: 1.6;text-align: left; font-family: MLI;
		p{   display: inline-block;float: left;margin-right: 20px;
			&:nth-child(1){
				span{color:#205bbc;font-family: MLB;}
			}
			&:nth-child(2){
				span{color:#be0000;font-family: MLB;}
			}
			&:nth-child(3){
				span{color:#dea433;font-family: MLB;}
			}
		}
	}
}


// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Dịch vụ
.dichvudk{}
.dichvu{  background: #f8f8f8;
	&__item{    position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;overflow:hidden;
		.transition(all ease 0.5s);
		
	}
	&__hover{    overflow: hidden;
    padding-top: 37px;
    background: url(images/icdv.png) no-repeat top center;
		img{    border-radius: 50%;}
	}
	&__item:hover &__hover img{.transition(all ease 0.5s); .transform(scale(1));}
	&__title{background:url(../images/ictd.png) no-repeat bottom center;    padding-bottom: 15px;background-size: 35px;text-align: center;text-transform:uppercase;margin-bottom:20px; a{color: #000;font-family: USC;font-size: 28px;}}
	&__img{ position: relative;background: #fff;opacity:1;.transition(all ease 0.5s);position:relative;
		img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
		&:hover img{.transition(all ease 0.5s);.transform(scale(1.1)) }
		&:after{
			// content:'';
			position: absolute;width: 100%;height: 100%;background:#00000057;z-index: 1;top: 0px;left: 0px;}
		}
		&__content{text-align:left;    margin-bottom:10px;}
		&__name{text-align: center; 
			padding: 10px;
			z-index: 9;
			a{ 
				    font-family: TB;

    font-size: 25px;
    color: #f4af35;
    outline: none !important;
    text-align: right;
				span{ position: absolute;right: -25px;top:calc(~"50% - 25px");
				img{width: auto;}
			}
		}
	}
	&__ab{
		position: absolute;
		bottom: 0px;
		right: 0px;
		z-index: 99;
		width: 170px;
		color: #fff;
	}
	
	&__des{      
		    line-height: 1.8;
    text-align: center;
    font-size: 13px;
    color: #999999;
    font-family: RR;
	}
	&__view{    text-align: center;
		a{  
			font-family: MLI;
			margin-top: 15px;
			line-height: 30px;
			display: inline-block;
			position: relative;
			padding: 0 20px;
			background: #d00;
			color: #fff;
		}
	}

}


// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Dự án
.duan{ display: flex;position: absolute;width: 120%;height: 100%;left: -10%;    top: 0px;
	&__box{

	}
	&__item{     overflow: hidden;
    position: relative;
    width: 25%;
    height: 100vh;
    padding-top: 12%;
    text-align: center;    transform: skew(-8deg);
		&:hover img{
			.transition(all ease 0.5s);.transform(scale(1.1));
		}
		&:after{content:'';position: absolute;width: 100%;height: 100%;top:0px;left: 0px;background:#00000063;background-size: cover;}
	>div{z-index: 5;position: relative;}
	&:hover{
		&:after{
			background:#ffffff59;
		}
	}
	&:hover{
		// .duan__tieude--list,a{color:#000;}
	}

	}
	&__item{
		&:nth-child(1){width:35%;    padding-left: 5%;}
		&:nth-child(4){width:35%;    padding-right: 5%;} 
	}
	&__img{
		overflow: hidden;
		img{.transition(all ease 0.5s);.transform(scale(1));}
	}
	&__content{
		position: absolute;width: 100%;bottom:0px;left: 0px;
		text-align: center;background:#0000005c;

	}
	&__tieude--list{font-family: OPL;
    font-size: 38px;
    text-transform: uppercase;
    transform: skew(8deg);
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: url(images/icda.png) no-repeat bottom center;
    a{
	    color: #fff;
    }
}
	&__title{
		a{display: inline-block;padding:7px 0;color: #fff;font-family: OPR;font-size: 16px;}
	}
	&__des{color: #fff;padding: 0 20px 20px 20px}
	&__list{
		p{ padding: 7px 0;
			a{color: #fff;font-family: OPL;font-size: 18px;
				&:hover{color: #d00}
			}
		}

	}
}

.prd{
	&__item{       overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #f2f2f2;
    padding: 20px;
    min-height: 200px;
    &:hover{border:1px solid #000;}
    &--center{
    &:hover{border:1px solid #000;}
    	min-height: 400px;
    border: 1px solid #f2f2f2;
    text-align: center;span{color: #6c2609}
    }
		&:hover img{
			.transition(all ease 0.5s);.transform(scale(1.1));
		}
	>div{z-index: 5;position: relative;}
	&:hover{
		&:after{
			background:#ffffff59;
		}
	}
	
	}
&__title{
	a{
		font-family: OPB;
    color: #000;
    font-size: 17px;
	}
}
&__price{
	span{color: #6c2609}
}
	&__tieude--list{font-family: OPL;
    font-size: 38px;
    color: #fff;
    text-transform: uppercase;
}
&__title--list{
	font-family: OPL;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;

}
&__title--center{
	a{
		font-family: OPB;
    color: #000;
    font-size: 17px;
	}
}
	&__list{ margin-bottom:20px;
		p{ padding: 7px 0;
			a{color: #fff;font-family: OPL;font-size: 18px;font-style: italic;}
		}

	}
	&__view {text-align: center;}
	&__view a{
		    color: #fff;
    border: 1px solid #fff;
    display: inline-block;
    padding: 5px 20px;
	}
}

// // ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Bài viết

.baiviet{
	&__item{    position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 20px;
		border-radius: 5px;
		&:hover &__border{transition:all ease 0.2s;transform: scale(1);}
	}
	&__border{   transition: all ease 0.2s;
    transform: scale(1);
    border: 1px solid #d9d9d95e;
    padding: 7px;
    background: #f7f7f7;}
	&__img{ background: #fff;opacity:1;.transition(all ease 0.5s);
		img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
		&:hover{opacity:0.8;transition: all ease 0.5s;}
	}
	&__date{
    font-size: 13px;
    color: #c4c4c4;
	}
	&__content{text-align:left;}
	&__name{      margin-top: 10px;

		a{      color: #000;
		    font-family: QB;
		    text-transform: uppercase;
		    display: inline-block;
		}
	}
	&__des{line-height: 1.6;text-align: left;}
}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
//Trang Liên hệ
.contact{
	&__content{margin-bottom: 10px;}
	&__map{padding-bottom:47%;height: 0px;position: relative;width: 100%;
		iframe{position: absolute;width: 100%;top: 0px;height: 100%;left: 0px;}
	}
	&__stip {
		border: 0px;
		padding: 10px 20px 10px 15px;
		color: #000;
		height: 40px;
		outline: none;margin-bottom: 5px;
		width: 100%;
		background: #f2f2f2;    font-size: 12px;
		.placeholder('RR',#555,'','','');
	}
	&__btnfrm {      padding: 0px 16px;
		background: #ce2828;
		color: #fff;
		line-height: 32px;
		border: 1px solid #ce2828;
		font-family: OPB;
		text-transform: uppercase;
		border-radius: 2px;
	}
}

// //////////////////////////////////
// ///////////////////////////
// Left index

.menusl{background:#0160ca;  
	border-radius: 7px 0 0 7px;
	padding: 20px 0px;min-height: 423px;position:relative;z-index: 99;
	&__link{ 
		// &:last-child a:after{display: none;}
		>ul{ 
			li{list-style: none;    border-bottom: 1px solid #f2f2f23b;
				a{        color: #000;
					position: relative;
					display: inline-block;
					padding: 15px 10px 15px 50px;    background: url(../images/icl.png) no-repeat 20px;
					width: 100%;
					font-family:"RR";color:#fff;
					font-size: 15px;text-transform:uppercase;
					

				}
				&:hover >a{text-decoration: underline;}
				&:hover >ul{visibility: visible !important;}
			}
			ul{     position: absolute;
				left: 100%;
				top: 0px;
				visibility: hidden;
				width: 100%;
				background:#0160ca;
				height: 100%;padding:20px;
				li{list-style: none;
					a{    color: #fff;
						position: relative;
						display: inline-block;font-family:"RR";text-transform:none;
						padding: 7px 0px 7px 50px;
						font-weight: normal;font-size: 14px;
						&:after{ left: 35px;
							top: 7px;
						}
					}
					&:hover >a{text-decoration: underline;}
				}
			}
		}
	}
}

.left{ 
	&__box{
		    border: 1px solid #d9d9d9;
	}
	.product__border{border:0px !important;}
	&__imghl{  
		       position: absolute;
		    font-size: 16px;
		    background: #000000c4;
		    width: 100%;
		    bottom: 0px;
		    padding: 5px;
		    color: #fff;
		    font-family: RB;
		span{color:#ff0;}
	}
	&__hl{padding:0 10px;}
	&__item{
		box-shadow: 0px 0px 5px #c4c4c4;
		padding-top: 10px;
	}
	&__link{     padding:0px 10px 10px 10px;

		// &:last-child a:after{display: none;}
		>ul{
			>li{list-style: none;
				>a{     color: #000;
    position: relative;
    display: inline-block;
    padding: 10px 35px 10px 15px;
    width: 100%;
    font-family: "OPR";
    background: url(images/v.png) no-repeat left;
    border-bottom: 1px dashed #dedddd;
					&:after{    
						border-radius: 100%;
						// content: '\f101';
						position: absolute;
						font-family: FontAwesome;
						left: 15px;
						top: 5px;
					}

				}
				&:hover >a{text-decoration: underline;}
			}
			ul{ visibility: hidden;height: 0px;
				li{list-style: none;
					a{  color: #000;
    position: relative;
    display: inline-block;
    font-family: "RR";
    text-transform: none;
    padding: 5px 10px 5px 30px;
    font-weight: normal;
    background: url(images/afn.png) no-repeat 20px;
    font-size: 12px;
						&:after{ left: 35px;
							top: 7px;
						}
					}
					&:hover >a{text-decoration: underline;}
				}
			}
			&:hover ul{ visibility: visible;height: auto;

			}
		}
	}
	&__hotro--item{text-align: left;border-bottom: 1px solid #f2f2f2;margin-bottom: 5px;padding-bottom:5px;}
	&__hotro--email{
		font-size: 13px;
	}

	&__input--txt{     color: #fff;
    height: 40px;
    padding: 10px 70px 10px 15px;
    width: 100%;
    background: #fff;
    border: 1px solid #f2f2f2;
    margin-bottom: 5px;
	}
	&__input--btn{      background: #000;
		color: #fff;
		float: right;
		border: 0px;
		padding: 7px 15px;
		display: inline-block;
		border-radius: 20px;}
		&__hotro--hl{
			p{
					   font-family: RR;
    color: #000;
				&:nth-child(1){    font-family: RR;
    font-size: 15px;
    margin-bottom: 10px;}
				&:nth-child(2){   
					    padding-left: 28px;
					    background: url(images/leftf.png) no-repeat 0px;
					}
				&:nth-child(3){   
					    padding-left: 28px;
					    background: url(images/leftm.png) no-repeat 0px;
				}
			}
		}
		&__hotro{
		}
		&__hl{     padding: 10px;
			p{ 

			}
		}

		&__add{background:url(../images/left-2.png) 0px 10px no-repeat;
			font-family: RM;    padding-top: 10px;
			padding-left: 30px;
			margin-top: 10px;
			border-top: 1px solid #d9d9d9;
			p{font-family: RR;color: #777777;font-size:13px;}
		}
		&__mail{background:url(../images/left-3.png) 0px 10px no-repeat;
			font-family: RM;    padding-top: 10px;
			padding-left: 30px;
			margin-top: 10px;
			border-top: 1px solid #d9d9d9;
			p{font-family: RR;color: #777777;font-size:13px;}

		}
	}


// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Tin tức bottom
.botnews{background: url(images/brnews.png) no-repeat;
    background-size: cover;
    &__flex{display: flex;justify-content: space-between;}
	&.bgyk{background: #f2f2f2;}
	&__dot{
		width: 20px;
		height: 20px;
		border: 1px solid #000;
		border-radius: 50%;
		position: absolute;
		left: 28%;
		z-index: 99;
		top: 45%;
		background: linear-gradient(to right,#fff, #c4c4c4);
		box-sizing: border-box;
	}
	&__item--hot{    padding-bottom: 10px;}
	&__news--hot {}

	&__title--hot{text-align: center;margin-bottom: 7px;
		a {
			font-family: Arial;
		    font-weight: 600;
		    font-size: 15px;
		    color: #fff;
		    margin-top: 10px;
		    display: inline-block;
		    width: 100%;
		    text-transform: uppercase;
		}
	} 

	&__content--hot {    
		position: relative;
		z-index: 99;
		text-align: left;
		font-family: RR;
		font-size: 13px;
		line-height: 1.8;
	}

	&__des--hot {
		line-height: 23px;
		font-size: 13px;
		color: #fff;text-align: center;
		a{color: #ff0;}
	}

	&__post{ display: flex;align-items: center;justify-content: space-around;
		p{	padding: 5px 0px;
			&:nth-child(1){padding-left:25px; font-family: OPR;color:#939393;background:url(../images/icd.png) 0px no-repeat; }
			&:nth-child(2){font-family: MLI;font-size: 12px;justify-content: flex-end;
				a{color:#018e01;}
			}
		}
	}

	&__news--other{
		position: relative;
		&:after{
			content: '';
			position: absolute;
			width: 1px;
			height: 100%;
			top: 0px;
			left: 29.5%;
			background: #acacac;
		}
	}
	&__item--other {    margin-bottom: 37px;
    position: relative;
    margin-right: 12px;
    display: flex;
    align-items: center;
	}

	&__img--other{position: relative;width:40%; margin-right: 10px;}
	&__img--hot img{}
	.botnews img{
		border:1px solid #d9d9d9;
	}
	&__title--other { margin-bottom:5px;    display: flex;
		a {       color: #000;
    font-family: OPB;
    line-height: 20px;
		}
	}
	&__img--yk{width: 25%;margin-right: 10px;}
	&__content--yk{width: 75%}
	&__content--other { 
		width: 60%;
	}
	&__des--other {
		    font-family: UAR;
    color: #555;
    font-size: 16px;
	}

	&__title--detail { margin-bottom:5px;text-align: center;
		a {
			margin-bottom: 10px;
			text-align: center;
			padding: 8px 0;
			color:#000;display: inline-block;    font-family: QB;
			font-size: 15px;
		}
	}

	&__view {    margin-top: 10px;
		a{  color: #fff;
		text-align: center;
    background: #ca3500;
    width: 127px;
    position: relative;
    line-height: 44px;
    display: inline-block;
    text-transform: uppercase;
			&:after{   
    right: 4px;
    position: absolute;
    top: 4px;}
		}
	}
	&__link{ background:#f2f2f2;padding:15px;
		ul{
			li{
				list-style: none;
				a{    color: #000;
					font-size: 13px;
					background: url(../images/dotnews.png) no-repeat 0px;    padding: 4px 0 4px 10px;display: inline-block;width: 100%;
				}
			}
		}
	}
	&__change--clip{
		box-shadow: 0px 0px 10px #c4c4c4;
		padding: 15px;
	}
	&__change--slt{     width: 100%;
		line-height: 40px;
		padding: 5px;
		color: #9c9c9c;
		border: 1px solid #cbcac9;
		margin-top: 6px;
	}
	&__change--show{height: 0px;position: relative;padding-bottom: 75%;
		iframe{position: absolute;width: 100%;height: 100%;top:0px;left:0px;}
	}

	&__clickf--show{    height: 0px;
		position: relative;
		padding-bottom:52%;
		padding-right: 20%;
		margin-bottom: 10px;
		iframe{position: absolute;width: 100%;height: 100%;top:0px;left:0px;}
	}
	&__clickf--box{
		margin:0 -5px;
	}

	&__clickf--item{
		position: relative;height: 0px;padding-bottom: 63%;
		img{position: absolute;width: 100%;height: 100%;left: 0px;top:0px;}
		span{width:30px;height:30px;position:absolute;top:0px;bottom:0px;margin:auto;left:0px;right:0px;z-index: 9;background: url(../images/icy.png);border-radius: 50%;background-size: 100% 100%;cursor: pointer;}
	}

	&__clickl{display: flex;justify-content: flex-start;}
	&__clickl--show{height: 0px;position: relative;padding-bottom: 57%;width: 76%;margin-right: 1%;
		iframe{position: absolute;width: 100%;height: 100%;top:0px;left:0px;}
	}
	&__clickl--box{
		margin:0 -5px;
		height: 0px;padding-bottom: 20%;height: 0px;position: relative;width: 23%;padding-bottom: 57%;    overflow-y: scroll;
		.srollbar(#555);
	}
	&__clickl--item{
		position: relative;height: 0px;padding-bottom: 90%;margin-bottom: 10px;
		img{position: absolute;width: 100%;height: 100%;left: 0px;top:0px;}
		span{width:30px;height:30px;position:absolute;top:0px;bottom:0px;margin:auto;left:0px;right:0px;z-index: 9;background: url(../images/icy.png);border-radius: 50%;background-size: 100% 100%;cursor: pointer;}
	}

&__time{     color: #adadad;
    margin-right: 10px;
    text-align: left;
    padding: 5px 0px;
		span{
			&:nth-child(1){padding-left: 20px;background:url(images/b1.png) no-repeat 0px;margin-right: 10px;} 
			&:nth-child(2){color:#000;padding-left: 20px;background:url(images/b2.png) no-repeat 0px;}
			}
		}

	&__date{       
        text-align: center;
    background: #540010;
    padding: 5px 0px;
    width: 80px;
    margin-right: 10px;
		p{
			text-align:center;
				color: #fff;
			&:nth-child(1){font-size: 30px;    line-height: 20px; 
				font-family: OPB;font-size: 18px;} 
			&:nth-child(2){font-size: 15px;font-family: OPR;}
			}
		}
		&__dangky {}
		&__title--dk{    margin-top: 10px;
			background: url(../images/ttdk.png);
			padding: 20px 0px;
			background-size: cover;
			text-align: center;    margin-bottom: 10px;
			min-height: 120px;
			background-size: 100% 100%;
		}
		&__stip {
			border: 0px;
			padding: 10px 20px 10px 15px;
			color: #000;
			height: 40px;
			outline: none;
			width: 100%;border:1px solid #d9d9d9;
			font-size: 12px;
			.placeholder('RR',#555,'','','');
		}
		&__btnsb {      padding: 0px 16px;
			background: #0260ca;
			color: #fff;
			line-height: 32px;
			border: 0px;
			font-family: OPB;
			text-transform: uppercase;
			border-radius: 2px;
			width: 200px;
		}
		textarea{height: auto !important;
		}

}

// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// Nhận tin bottom

.nhantin{     margin-top: 20px;
	position: relative;
	margin: 0px auto;
	z-index: 99;    background: url(images/brdk.png) no-repeat;
    background-size: cover;
	&__box{  max-width: 325px;float:right;    margin-right: 95px;
	}
	&__col{
		& .nhantin__txt{    width: 100%;
			margin-bottom: 10px;border-radius: 7px;border: 1px solid #555;}
			p{ 
				textarea{padding:12px 5px 5px 10px;height: 46px;background:#fff;border-radius: 40px;}
			}
		}
		&__title{       margin-bottom: 10px;
			    font-family: RB;
			    color: #d00;
			    text-transform: uppercase;
			    font-size: 20px;
			    text-align: center;
		}
		&__txt{     color: #fff;
			height: 45px;
			padding: 6px;
			float: left;
			padding-left: 15px;border:0px;
			background-color: #0000007a;
			.placeholder('RR',#c4c4c4,'12px','','');
		}
		input[name="ten"]{
			padding-left: 45px;
		    background: rgba(0, 0, 0, 0.57) url(images/nt1.png) no-repeat 0px;
		}
		input[name="diachi"]{
			padding-left: 45px;
		    background: rgba(0, 0, 0, 0.57) url(images/nt2.png) no-repeat 0px;
		}
		input[name="dienthoai"]{
			padding-left: 45px;
		    background: rgba(0, 0, 0, 0.57) url(images/nt3.png) no-repeat 0px;
		}
		input[name="email"]{
			padding-left: 45px;
		    background: rgba(0, 0, 0, 0.57) url(images/nt4.png) no-repeat 0px;
		}

		textarea[name="noidung"]{
			padding-left: 45px;
		    background: rgba(0, 0, 0, 0.57) url(images/nt5.png) no-repeat 5px 5px;
		}
		
		&__btn{     
			   background: #eba605;
    border: 0px;
    font-family: OPB;
    color: #000;
    width: 120px;
    padding: 10px 0;
    margin-top: 10px;
    border-radius: 7px;
    float: right;
		}
	}


// ////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////
// footer

.footer { position: relative;
	&__flex{
		display: flex;
    justify-content: space-between;
	}
	&__name{
		  font-weight: normal;
    font-family: UTMI;
    font-size: 25px;
    text-align: left;
    margin-bottom: 15px;
    color: #ff0;
    text-transform: uppercase;

		p{
			&:nth-child(1){        font-weight: normal;
    font-family: OPB;
    text-transform: uppercase;
    color: #9c0000;font-size: 34px;
    text-shadow: 1px 1px #fff, -1px -1px #fff, -1px 1px #fff, 1px -1px #fff, 0px -2px 0px #fff;}
			&:nth-child(2){    font-size: 32px;
    font-family: SSS;
    color: #fff;
    text-transform: none;
    margin-bottom: 20px;}
		}
		
	}
	&__title{    
		    position: relative;
    font-family: RB;
    line-height: 30px;
    display: inline-block;
    color: #ff0;
    font-weight: normal;
    text-transform: uppercase;
    padding-right: 10px;font-size: 16px;
    margin-bottom: 15px;
		p{
		    font-family: Arial;
		    text-transform: none;
		    font-size: 14px;
		}
		&:after{content:'';position: absolute;width: 50px;height: 2px;border-bottom:2px solid #fff;left: 0px;bottom: -2px} 
	}

	&__tags{list-style:none;margin:0;overflow:hidden;padding:0px;
		li{ float:left;display:inline-block;
			a{      display: inline-block;
				position: relative;
				text-decoration: none;
				float: left;
				color: #fff;
				font-size: 13px;
				padding: 7px 15px;
				border: 1px solid #555555a8;
				background: none;
				margin-bottom: 5px;
				margin-right: 5px;
			}
			a:hover{text-decoration: underline;}
		}
	}
	
	&__line{      border-bottom: 1px solid #e1e1e1;
		padding: 10px 0;}
	// .footer__bg--top{background:#ffffffa6;position: relative;}
	// .footer__bg--bot{background:#770f23e0;}
	// .footer__hotline{
	// 	width: 348px;
	// 	height: 67px;
	// 	background: url(images/footer__hl.png);
	// 	display: inline-block;
	// 	padding-left: 80px;
	// 	padding-top: 5px;
	// 	color: #fff;
	// 	font-size: 25px;
	// 	line-height: 25px;
	// 	font-family: OPB;
	// 	font-style: italic;
	// 	position: absolute;
	// 	bottom: 12px;
	// 	right: 0px;
	// 	p{
	// 		&:nth-child(2){
	// 			font-size: 20px;
	// 		}
	// 	}
	// }
		&__logo{text-align: center;margin-bottom: 20px;}
	&__address{
    line-height: 1.8;
    color: #fff;  
    font-family: RR;    
		p{    display: inline-block;width: 100%; margin-bottom: 15px;
			span{
				&:nth-child(2){}
			}

			a{position:relative;color: #999999;}
		}
		.fnd{margin-right: 5px;}
	}
	&__link{
		p{    padding: 5px;
    			// background: url(images/v.png) no-repeat 10px;
			a{       color: #fff;
    			font-family: RR;
    		} 
		}
	}
	&__fnd{display: inline-block;float: left;margin-right: 15px;}
	&__chungnhan{margin-top: 10px;}
	&__map{padding-bottom: 230px;
		height: 0px;
		position: relative;
		width: 100%;
		iframe{position: absolute;width: 100%;top: 0px;height: 100%;left: 0px;}
	}
	&__logo{
	}

	&__nhantin{    
		position: relative;  
		textarea{height: auto !important;font-family: RR;}
	}
	&__nhantin--flex{    display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__nhantin--title{
		    color: #fff;
    margin-bottom: 15px;
    font-size: 13px;
	}
	&__nhantin--txt{ color: #000;
    height: 40px;
    padding: 10px 70px 10px 15px;
    border: 0px;
    width: 100%;
    background: #fff;
    border: 1px solid #d7d7d7;
		.placeholder('RR',#555,'12px','','');
	}
	
	
	
	&__nhantin--box{position: relative;  
		p{position: relative;}
		span{
			position: absolute;left: -40px;top:0px;
		}
	}  
	&__nhantin--btn{     
	            background: #d00;
    height: 38px;
    font-family: RR;
    font-size: 15px;
    color: #fff;
    border-radius: 10px;
    border: 0px;
    padding: 0 20px;
	}

	&__menu{
		> ul{ list-style:none;transition:all;padding: 0px;display: inline-block;float: left; margin-right: 15px;
			>li { display:inline-block;text-align:center;position:relative;list-style:none;float:left;
				>a {    
					text-transform: uppercase;
					position: relative;
					line-height: 53px;
					padding: 0 51px;
					display: inline-block;
					color: #000;
					font-size: 18px;
					cursor: pointer;
					font-family: SFR;
				}
			}
		}
	}


	&__truycap{
		ul{
			li{list-style: none;color: #000;padding:5px 0;font-size: 13px;position: relative;
				span{
					color: #000;
				}
			}
		}
	}
	&__quydinh{padding:20px 0;color: #fff;
	}
}

.copyfooter{       position: relative;
    padding: 10px 0;
    background: #ba2020;
	// &:after{content:'';position: absolute;width: 142px;height: 34px;right:0px;left: 0px;top: -33px;margin:0px auto;background:url(../images/f-top.png)} 
	&__flex{display: flex;justify-content:space-between;}
	&__design{
    color: #fff;
		span{color: #ff0;}
	}
	&__truycap{display: inline-block;
		float: right;width: 35%;    text-align: right;
		ul{
			li{color: #fff;display: inline-block;position: relative;padding: 0px 10px 0px 10px;
				span{
					color: #ff0;
				}
				.aft-truycap{content:'';    height: 10px;
				width: 1px;
				right: 0px;
				top: 7px;
				position: absolute;}
				&:nth-child(1):before{.aft-truycap;background:#fff;}
				&:nth-child(2):before{.aft-truycap;background:#fff;}
				// &:nth-child(3):before{.aft-truycap;background:#fff;}
			}
		}
	}

}




.bg-mail{     padding: 30px 0;
	background: url(images/brdk.png) no-repeat;
	background-size: cover;
	min-height: 500px;
}
.newsdetail{
	&__item{     position: relative;border:1px solid #f2f2f2;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 5px;
		padding: 5px;
	}
	&__img{margin-right:10px;background: #fff;opacity:1;transition: all ease 0.5s;width: 35%;float: left;
		img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
		&:hover{opacity:0.8;transition: all ease 0.5s;}
	}
	&__content{text-align:left;}
	&__name{
		
		a{   color: #000;
			font-family: MLB;text-transform: uppercase;
			outline: none !important;
		}
	}
	&__call{color:#d00;font-family: RB; font-size: 13px;}
	&__des{color:#000;line-height: 1.6; font-size: 13px;font-family: MLI;margin-bottom: 10px;
	}
}

.dichvuid{
	&__item{     position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 5px;display: flex;align-items: center;
		padding: 5px;
	}
	&__img{margin-right:10px;opacity:1;transition: all ease 0.5s;width: 30%;
		img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
		&:hover{opacity:0.8;transition: all ease 0.5s;}
	}
	&__content{text-align:left;width: 70%;}
	&__name{
		a{   color: #fff;
			font-family: MLB;text-transform: uppercase;
			outline: none !important;
		}
	}
	&__des{color:#fff;line-height: 1.6; font-size: 13px;font-family: MLI;margin-bottom: 10px;
	}
}


.doitac{background: #eeeeee;
	&__item { img{ text-align: center;}}
	&__bg{
		padding: 15px 0 ;    
		margin: 0px auto;
	}
}


.dangky{    
	background: url(images/brdk.png) fixed;
	display: flex;
	background-size: cover;
	min-height: 360px;
	justify-content: center;
	align-items: center;

	&__content{position: relative;margin-top: 30px;max-width: 840px;margin:0px auto;}
	&__stip {
		border: 0px;
		padding: 10px 20px 10px 15px;
		color: #fff;
		height: 40px;
		outline: none;
		width: 100%;
		background: #fff;    font-size: 12px;
		.placeholder('RR',#555,'','','');
	}
	&__btnsb {      padding: 0px 16px;
		background: #cc0000;
		color: #fff;
		line-height: 32px;
		border: 1px solid #cc0000;
		font-family: OPB;
		text-transform: uppercase;
		border-radius: 2px;
	}

	textarea{height: auto !important;}

}


.bottom-video{
	background:url(../images/brvd.png) no-repeat; background-size: cover;
	position: relative;
	padding-bottom: 33%;
	div{display: flex;
		position: absolute;width: 100%;height: 100%;left: 0px;top:0px;justify-content: center;align-items: center;
	}
	fieldset{
		max-width: 365px;
		margin: 0px auto;
		border: 1px solid #fff;
		border-radius: 10px;
		width: 100%;
		padding: 3% 0;
		color: #fff;
		font-size: 28px;
		font-family: SFH;
		text-transform: uppercase;
		&:hover img{
			.animation1(nhay,all ease 0.5s,all ease 0.5s);
		}
	}
}

// mxh
.mxhf,.mxhit{ 
	ul{padding:0; display: flex;
	    align-items: center;  
		li{  
		list-style: none;
			a{color:#fff;font-family:RR;font-size:14px;}
		}
	}
}

.mxh{    
	ul{padding:0;display: flex;align-items: center;
		li{  margin-right: 5px;
			list-style: none;
			display: block;
			a{color:#fff;
			}

			&:nth-child(1){
				// border-left:1px solid #e7ac0b;
			}
		}
	}
}
.mxhf{  margin-right: 10px;
	ul{ padding: 0;
	   

		li{   
			display: inline-block;
			list-style: none;
			margin-right: 5px;
			a{color:#fff;font-family:RR;font-size:14px;}
		}
	}
}
.mxh p{font-family: RB;
	text-transform: uppercase; display: block;
	width: 100%;
	text-align: left;
}

// tieude
.ictd{
	padding: 0px 0 50px;
	background: url(../images/ictt.png) bottom center no-repeat;
	&:after{bottom: 68px !important;}
	p{background:#f8f8f8 !important;}
}
.tieude {       position: relative;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 80px;
    padding-top: 65px;
    background: url(images/icid.png) no-repeat top center;
	p{       color: #000;
    line-height: 30px;
    display: inline-block;
    position: relative;
    z-index: 2;
    font-size: 15px;
	}

	h3 { font-family: OPB;
    color: #6c2609;
    font-size: 33px;
    text-transform: uppercase;
	}
	a{font-family: UTC;
    float: right;
    color: #ee1c24;
    border-bottom: 1px solid;
    text-transform: uppercase;}
	span{display: inline-block;position: absolute;background: url(images/tdaf.png) no-repeat 0px;width: 56px;height: 59px;left:0px;z-index: 2;top:0px;
	} 
	&:after{     
		// content: '';
		position: absolute;
		width: 60px;z-index: 1;
		height: 2px;
		left: 0px;
		bottom: 0px;
		background: #ffd309;
	}
}
.tieude_left{ margin-bottom: 10px;
	h3{ 
    font-size: 17px;
    font-family: RB;
    display: inline-block;
    background: url(images/ictdl.png) no-repeat right,linear-gradient(to right,#ce2828 0px,#ce2828 calc(~"100% - 23px"),transparent calc(~"100% - 23px"));
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    line-height: 40px;
    padding: 0px 35px 0px 20px;
	}
}
.tieude_sl{  margin-bottom: 10px;position: relative;border-bottom:1px solid #ce2828;
	h3{
		font-size: 17px;
    font-family: RB;
    display: inline-block;
    background: url(images/ictdl.png) no-repeat right ,linear-gradient(to right,#ce2828 0px,#ce2828 calc(~"100% - 23px"), transparent calc(~"100% - 23px"));
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    line-height: 40px;
    padding: 0px 35px 0px 20px;

	} 
	span{float: right;
		a{color: #555;    padding: 8px;text-decoration: underline;
			font-style: italic;
			color: #d00;
		}
	}
	&:after{
		// content:'';
		position:absolute;width: 70px;height: 1px;left: 0px;bottom:-1px;background:#007aff;}

	}

	.tieude_main{       margin: 0px 0px 20px;
		border-bottom: 1px solid #ce2828;
		h3{
			font-size: 17px;
		    font-family: RB;
		    display: inline-block;
		    background: url(images/ictdl.png) no-repeat right,linear-gradient(to right,#ce2828 0px,#ce2828 calc(~"100% - 23px"),transparent calc(~"100% - 23px"));
		    text-transform: uppercase;
		    font-weight: normal; 
		    color: #fff;
		    line-height: 40px;
		    padding: 0px 35px 0px 20px;
			// text-transform: uppercase;
			&:after{
				content: '';
    position: absolute;
    width: 60px;
    height: 3px;
    left: 0px;
    bottom: -2px;
    z-index: 0;
    background: #c4c4c46b;
			}
		} 
	}


	.tieude_id{    
		   text-align: left;
    position: relative;
    margin-bottom: 15px;
		h3{font-weight: normal;
    z-index: 1;
    display: inline-block;
    text-transform: uppercase;
    font-family: RB;
    font-size: 30px;
    position: relative;
    color: #ff0;
		// &:after{content:'';position:absolute;width: 70px;height: 3px;left: 0px;bottom:-2px;border-radius: 5px;    background:#002e8e;}
	} 
	p{     max-width: 710px;
    color: #666666;
    margin: 0px auto;}
	}
	.tieude_dm{
		position: relative;
		h3{
			position: relative;
			cursor: pointer;
			padding: 0 10px 0 60px;
			margin: 0;
			background: #dc1b1b url(../images/icmn.png) no-repeat 15px;
			color: #fff;
			font-family: RB;
			text-transform: uppercase;
			font-size: 16px;
			line-height: 45px;
			text-align: left;
		}
	}
	.chitiet {margin-top: 10px;text-align: right;
		a {         position: relative;
			display: inline-block;
			font-family: MLR;
			color: #000;
			text-align: center;
			padding: 4px 20px;
			border: 1px solid #000;
			font-style: italic;
			font-size: 13px;
		}
	}
	.view {margin-top: 10px;text-align: left;
		a {          position: relative;
			display: inline-block;
			color: #000;
			text-align: center;
			background: #ffcc00;
			border-radius: 5px;
			width: 170px;
			text-transform: uppercase;
			line-height: 40px;
			font-family: RB;
			font-size: 15px;
			border: 0px;
		}
		&:hover a{color: #fff;background: #00438f;}

	}
	.xemthem{    text-align: center;
		a{        color: #000;
    width: 130px;
    line-height: 35px;
    display: inline-block;
    background: #f2f2f2;
    text-align: center;
    font-family: RR;font-size: 13px;
    text-transform: uppercase;
		}
	}

	.tieude_gt{      margin-bottom: 10px;
    text-align: center;
    padding-bottom: 10px;
    background: url(images/ictt.png) no-repeat bottom center;
		span{float: right;
			a{    color: #d00;
				line-height: 50px;
				font-style: italic;
				position: relative;
				padding-right: 20px;
				text-decoration: underline !important;
				display: inline-block;
			}
		}
		h3{       font-weight: normal;
    margin: 0px;
    font-family: UA;
    font-size: 40px;
    position: relative;
    color: #000;
    display: inline-block;

			&:after {
			// content: '';
			position: absolute;
			width: 100px;
			height: 2px;
			background: #000;
			left: 0px;
			bottom: -2px;
		}
		span{color: #ffa800;}
	}
	p{
		color: #525252;
		padding: 5px 20px 0px 90px;
		font-family: QR;
		text-align: left;letter-spacing: 3px;
		background: url(images/linevs.png) no-repeat 0px 18px;
	}
}

.content_detail{
	p{
		&:hover{text-decoration:underline!important}
		a{color: #000;position:relative;font-family:Arial;font-size:14px;line-height:25px;padding-left:15px;
			&:after{content:'\f101';position:absolute;font-family:FontAwesome;left:0px;top: -4px;}
		}
	}
}


.call{background: #fff !important;height: 50px;position: fixed;width: 100%;bottom: 0;left: 0;z-index: 999999999999;display: none;border-top: 1px solid #fff;padding-top: 5px;
	.ex-call{width:25%;text-align:center;float:left;}
	.phone{.ex-call;color:#fff;-webkit-box-sizing:border-box;box-sizing:border-box;    
		-webkit-animation-name:op_phone;-webkit-animation-duration:1s;-webkit-animation-timing-function:linear;-webkit-animation-iteration-count:infinite;-moz-animation-name:op_phone;-moz-animation-duration:1s;-moz-animation-timing-function:linear;-moz-animation-iteration-count:infinite;animation-name:op_phone;animation-duration:1s;animation-timing-function:linear;animation-iteration-count:infinite;
		a{background: url(../images/icon-t1.png) no-repeat center 2px;background-size:21px;    padding-top: 21px;
			display: inline-block;
			width: 100%;
		}
	}
	.sms{.ex-call;a{  padding-top: 21px;
		background: url(../images/icon-t2.png) no-repeat center 2px;
		background-size: 25px;
		display: inline-block;
		width: 100%;}
	}
	.zalo{.ex-call;a{   padding-top: 21px;
		background: url(../images/icon-t3.png) no-repeat center 2px;
		background-size: 45px;
		display: inline-block;
		width: 100%;}
	}
	.face{.ex-call;a{    padding-top: 21px;
		background: url(../images/icon-t4.png) no-repeat center 2px;
		background-size:21px;
		display: inline-block;
		width: 100%;}
	}
	a{color: #555;font-size: 11px;}
}
@-webkit-keyframes op_phone{0%{opacity:1.0}50%{opacity:0.0}100%{opacity:1.0}}
@keyframes op_phone{0%{opacity:1.0}50%{opacity:0.0}100%{opacity:1.0}}





.mg-b{margin-bottom: 10px}
// ///////////
.col-5{width: 20%;float:left;}
.col-5:nth-child(5n+1){clear:both;}
.col-4{width: 25%;float:left;}
.col-4:nth-child(4n+1){clear:both;}
.col-3{width:33.33%;float:left;}
.col-3:nth-child(3n+1){clear:both;}
.col-2{width:50%;float:left;}
.col-2:nth-child(2n+1){clear:both;}

.pd-30{padding: 0px 50px;}
.mg-30{margin: 0px -50px;}
.pd-20{padding: 0px 20px;}
.mg-20{margin:0 -20px;}
.pd-15{padding: 0px 15px;}
.mg-15{margin:0 -15px;}
.pd-10{padding: 0px 10px;}
.mg-10{margin:0 -10px;}
.pd-5{padding: 0px 5px;}
.mg-5{margin:0 -5px;}
.pd-0{padding:0px;}
.mg-0{margin:0px;}

.mgb-5{margin-bottom: 5px;}
.mgb-10{margin-bottom: 10px;}
.mgb-15{margin-bottom: 15px;}
.mgb-20{margin-bottom: 20px;}
.mgb-25{margin-bottom: 25px;}
.mgb-30{margin-bottom: 30px;}
.mgb-35{margin-bottom: 35px;}
.mgb-40{margin-bottom: 40px;}
.mgb-45{margin-bottom: 45px;}
.mgb-50{margin-bottom: 50px;}
.mgt-5{margin-top: 5px;}
.mgt-10{margin-top: 10px;}
.mgt-15{margin-top: 15px;}
.mgt-20{margin-top: 20px;}
.mgt-25{margin-top: 25px;}
.mgt-30{margin-top: 30px;}
.mgt-35{margin-top: 35px;}
.mgt-40{margin-top: 40px;}
.mgt-45{margin-top: 45px;}
.mgt-50{margin-top: 50px;}
.pdt-5{padding-top:5px ;}
.pdt-10{padding-top:10px;}
.pdt-15{padding-top:15px;}
.pdt-20{padding-top:20px;}
.pdt-25{padding-top:25px;}
.pdt-30{padding-top:30px;}
.pdt-35{padding-top:35px;}
.pdt-40{padding-top:40px;}
.pdt-45{padding-top:45px;}
.pdt-50{padding-top:50px;}
.pdb-5{padding-bottom:5px ;}
.pdb-10{padding-bottom:10px;}
.pdb-15{padding-bottom:15px;}
.pdb-20{padding-bottom:20px;}
.pdb-25{padding-bottom:25px;}
.pdb-30{padding-bottom:30px;}
.pdb-35{padding-bottom:35px;}
.pdb-40{padding-bottom:40px;}
.pdb-45{padding-bottom:45px;}
.pdb-50{padding-bottom:50px;}


.generate-columns(100);
.generate-columns(@n, @i: 1) when (@i =< @n) {
	.wid-@{i} { width: (@i * 100% / @n); }
	.generate-columns(@n, (@i + 1));
}
[class*="wid-"]{float: left;}
// custom css
.cl-b{color:#c6c6c6 !important;}
.cl-w{color:#fff !important;}
.bg-w{color:#fff;}
.text-center{text-align: center ; } 
.text-right{text-align: right ; } 
.text-left{text-align: left ; } 
.float-left{float: left ; } 
.float-right{float: right;}

// end 
@path-fonts:'../fonts/';
.font-face(@name,@font){
	@font-face {
		font-family: '@{name}';
		src: url('@{path-fonts}@{font}.eot');
		src: url('@{path-fonts}@{font}.eot?#iefix') format('embedded-opentype'),
		url('@{path-fonts}@{font}.woff') format('woff'),
		url('@{path-fonts}@{font}.ttf') format('truetype'),
		url('@{path-fonts}@{font}.svg#@{font}') format('svg');
		
	}
}

.font-one(@name,@font){
	@font-face {
		font-family: '@{name}';
		src: url('@{path-fonts}@{font}.ttf') format('truetype');		
	}
}

.font-one(RB,RobotoBold);
.font-one(OPB,OpenSansBold);
.font-one(OPR,OpenSansRegular);
.font-one(OPL,OpenSansLight);
.font-one(UTMI,UTMHelvetIns);
.font-one(RR,RobotoRegular);
.font-one(RM,Roboto-Medium);
.font-one(SA,SVN-Appleberry);
.font-one(SSU,SVN-Segoe UI );

.hide{display: none;}

 

.item-bst{
	overflow: hidden;
	position: relative;
	border:1px solid #fff;
}
.name-bst{text-align: center;}
.item-bst .hover:hover{transition:all ease 0.5s;} 
.item-bst a:hover:after{content:'';position:absolute;width:100%;height:100%;left:0px;top:0px;background:#00000070;} 
.item-bst .hover:hover a:after{visibility:visible !important}

.item-bst:hover .name-bst{visibility:visible}
.item-bst .name-bst {
	position: absolute;
    bottom: 0;
    display: table;
    width: 100%;
    z-index: 2;
    padding: 10px;
    font-family: OPB;
    text-transform: uppercase;
    text-align: center;
    visibility: hidden;
color: #fff;
}
.item-bst a:hover .name-bst a:after{display:none}
.item-bst a:hover .name-bst:before {
	left: 20px;
	-webkit-animation: af_ 0.3s ease-in-out both;
	-ms-animation: af_ 0.3s ease-in-out both;
	-o-animation: af_ 0.3s ease-in-out both;
	animation: af_ 0.3s ease-in-out both;
	-webkit-animation-delay: 0.4s;
	-ms-animation-delay: 0.4s;
	-o-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
// .dis-list{color: #fff;font-family: OPR;line-height: 1.8;}
.item-bst .name-bst:before {
	content: "";
	position: absolute;
	left: 25px;
	top: 0;
	bottom: 0;
	height: 1px;
	width: 0;
	background: #fff;
	margin: auto;
}

@keyframes pl_ {
	form {
		padding-left:25px
	}
	to {
		padding-left: 50px
	}
}
@keyframes af_ {
	form {
		width:0
	}
	to {
		width: 15px
	}
}
.item-bst .name-bst span{
	position: absolute;
	width: 50px;
	height: 100%;
	right: 0px;
	z-index: 9;
	top: 0px;
	background: #dd0000  url(../images/sp/v2.png) no-repeat center center;
}
.item-bst .name-bst a{    color: #fff;
	font-family: UTAVb;}

.owl-nav .owl-next {
    right: 0px;
}
.owl-nav .owl-prev {
    left: 0px;
}

.box_img_pr,.main_images{border:1px solid #f2f2f2;}

.lienket{
	background: url(images/brlk.png) no-repeat;background-size: cover;
}
.itemprd{ text-align: center;    margin-top: 30px;margin-bottom: 30px;
	&__img{     
		overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    border: 7px solid #d9d9d9;
    box-shadow: 0px 0px 5px #c4c4c4;
		img{border-radius:50%;}
	}
	&__name{
		a{color: #fff;
    	font-size: 20px;}
	}
}

.bg-prd{
	    background: url(images/brprd.png) no-repeat top center;
}

.album{
	background: url(images/brab.png) no-repeat top center;
}

.slicksp .pd-15:not(.slick-slide){
display: none;
}
.bg-index{
	background: url(images/brid.png) no-repeat; background-size: cover;
}

.contentvs{
	    height: 258px;
    position: relative;
    background: url(images/ctvs.png) no-repeat;
    margin: 30px 0 75px;
}

.visao__item{position: absolute;     min-height: 55px;
	&:nth-child(1){
    left: 140px;
    bottom: -55px;
	}
	&:nth-child(2){
		left: 0px;top:100px;
	}
	&:nth-child(3){
		right: 0px;
    	top: 100px;
	}
	&:nth-child(4){
		right: 140px;
    	bottom: -55px;
	}

}

.khachhang{
	&__flex{

	}
	&__mota{    max-width: 595px;
    color: #fff;
    margin-bottom: 25px;line-height: 28px;
}
	&__view{ text-align: left;
		a{ 
			color:#fff;border:1px solid #fff;
			display: inline-block;padding: 5px 20px;
		}
	}
}

.sliderduan{
	position: relative;margin-bottom:30px;
}
.nameda{
    position: absolute;
    z-index: 999;
    left: 25px;
    bottom: 15px;
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    font-family: MB;
}

  .row-duan {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
}.row-duan p:nth-child(1) {
    font-family: MB;
    text-transform: uppercase;
}
.row-duan:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #f2f2f2;
    bottom: -3px;
    left: 0px;
}
.contentda{
  
}

.newsleft{
	flex-flow: row-reverse;
}


.nguyenlieu{
	&__item{     position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-bottom: 5px;display: flex;align-items: center;
		
	}
	&__img{margin-right:50px;opacity:1;transition: all ease 0.5s;width: 40%;
		img{.transition(all ease 0.4s);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);width: 100%;}
		&:hover{opacity:0.8;transition: all ease 0.5s;}
	}
	&__content{text-align:left;width: 60%;}
	&__name{margin-bottom: 10px;
		a{      color: #000;
    font-family: OPB;
    text-transform: uppercase;
    outline: none !important;
    font-size: 24px;
		}
	}
	&__des{color: #000;
    line-height: 1.8;
    font-family: OPR;margin-bottom: 10px;
	}
	&__view{    text-align: left;
		a{  
			font-family: OPR;
    margin-top: 10px;
    width: 126px;
    height: 39px;
    padding-right: 15px;
    display: inline-block;
    position: relative;
    border: 1px solid #d9d9d9;
    background: url(images/brview.png) no-repeat;
    color: #fff;
    text-align: center;
    line-height: 33px;
    background-size: 100% 100%;
		}
	}

}

.bg-index{background: #f2f2f2;}

.bg-index .nguyenlieu__item{
flex-flow: row-reverse;
}
.bg-index .nguyenlieu__img{margin-left:50px;margin-right: 0px}


.giaoduc{ text-align: center;
	&__img{}
	&__title{
		   line-height: 30px;
    margin-bottom: 20px;
    font-family: OPR;
    font-size: 20px;
		div{
			&:nth-child(2){
				font-family: TB;font-size: 35px;
			}
		}
	}
	&__content{
		div{
		    border-radius: 5px; 
		    margin-bottom: 9px;
		    padding: 15px 10px;
		    box-shadow: inset 0px 0px 15px #c4c4c4;    color: #fff;
			&:nth-child(1){
				background: #ffbe01;
				
			}
			&:nth-child(2){
				background: #0283c8;
				a{color: #fff;}
			}
			&:nth-child(3){
				background: #de0f0f;
			}
			p{
				&:nth-child(2){
					font-family: TB;
					font-size: 20px;
				}
			}

		}
	}
} 

.chuongtrinh{background: url(images/brct.png) no-repeat; background-size: cover;}

.lichhoc{
	&__content{
		border: 1px solid #f2f2f2;
	    padding: 20px;
	    p{padding:8px 0}
	    label{
			font-family: MB;
	    }
	    span{ font-family: MB;color: #d00
	    }
	    a{height: 45px;
    display: inline-block;
    background: url(images/brlh.png);
    width: 159px;
    text-align: center;
    line-height: 45px;
    color: #fff;
}
	}
	&__name{
		    background: url(images/brname.png) no-repeat;
    color: #fff;
    padding:8px 5px 8px 45px;
    font-family: TB;
    font-size: 18px;
    text-transform: uppercase;
    background-size: cover;
    position: relative;
		&:after{content:url(images/icl.png);position: absolute;left: 5px;top:5px;}
		
	}
}
.congdung{
background: url(images/brcd.png);background-size: cover;
&__name{
	display: flex;justify-content: center;max-width: 870px;
}
&__title{    width: 50%;
    font-family: OPB;
    font-size: 33px;
    color: #6c2609;
	span{
		&:nth-child(1){}
		&:nth-child(2){font-family: SA;
    font-size: 60px;
    position: relative;
    top: 25px;
    line-height: 50px;}
		&:nth-child(3){position: relative;
    top: 30px;}
	}
}
&__des{width: 50%;width: 50%;
    margin-top: 25px;}
}


	.main_clcd { margin: 45px 0 35px 0; }
.utility-cate {     margin-top: 15%;
    position: relative;
    padding-bottom: 10%;}
.utility-wrap { width: 515px; margin: auto; position: relative;  }
.ellip-cir { display: block; margin: 0 auto;	/* flip img */ -moz-transform: scale(-1); -o-transform: scale(-1); -webkit-transform: scale(-1); transform: scale(-1); filter: FlipH; -ms-filter: "FlipH";	/* end flip img */ position: relative; }
.ellip-cir .ellipse {stroke: #E4C896;stroke-width: 2;stroke-dasharray: 1870;stroke-dashoffset: 1870;/*animation: offset 16s linear forwards;*/}
.utility-item { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.utility-item .item1 {width: 180px;height: 180px;border-radius: 50%;position: relative;text-align: center;background: #fff url(images/brdot.png) no-repeat;background-size: 100%;position: absolute;margin: auto;opacity: 0;}
.utility-item .item1 p {    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    font-size: 24px;
    line-height: 24px;
    font-family: SA;
    width: 155px;
    color: #521e09; }
.utility-item .item1:nth-of-type(1) { left: calc(~"0% - 80px"); top: 0px;	/* transform:translate(-315px,-120px); */	/* animation: fade 0.1s linear forwards; */ }
.utility-item .item1:nth-of-type(2) { left: calc(~"50% - 90px"); top: -120px;	/* transform:translate(-125px,-218px); */	/* animation: fade 2.1s linear forwards; */ }
.utility-item .item1:nth-of-type(3) { left: calc(~"100% - 80px"); top: 0px;	/*transform:translate(125px,-218px);*/	/*animation: fade 4.1s linear forwards;*/ }
.utility-item .item1:nth-of-type(4) { left: calc(~"100% - 80px"); bottom: 0px;	/*transform:translate(315px,-120px);*/	/*animation: fade 6.1s linear forwards;*/ }
.utility-item .item1:nth-of-type(5) { left: calc(~"50% - 90px"); bottom: -120px;	/*transform:translate(315px,120px);*/	/*animation: fade 8.1s linear forwards;*/ }
.utility-item .item1:nth-of-type(6) { left: calc(~"0% - 80px"); bottom: 0px;	/*transform:translate(125px,218px);*/	/*animation: fade 10.1s linear forwards;*/ }
@keyframes offset {
	100% { stroke-dashoffset: 0; }
}
@-webkit-keyframes offset {
	100% { stroke-dashoffset: 0; }
}
@keyframes fade {
	0% { opacity: 0; }
	50% { opacity: 0; }
	95% { opacity: 0; }
	100% { opacity: 1; }
}
@-webkit-keyframes fade {
	0% { opacity: 0; }
	50% { opacity: 0; }
	95% { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes fade-down {
	100% { opacity: 1; bottom: 0; }
}
@-webkit-keyframes fade-down {
	100% { opacity: 1; bottom: 0; }
}
@keyframes fade-slow {
	100% { opacity: 1; }
}
@-webkit-keyframes fade-slow {
	100% { opacity: 1; }
}
@keyframes fade-down-top {
	100% { top: 0; }
}
@-webkit-keyframes fade-down-top {
	100% { top: 0; }
}
@keyframes fade-left {
	100% { opacity: 1; left: 0; }
}
@-webkit-keyframes fade-left {
	100% { opacity: 1; left: 0; }
}
@keyframes fade-right {
	100% { opacity: 1; right: 0; }
}
@-webkit-keyframes fade-right {
	100% { opacity: 1; right: 0; }
}
.img_cc{
	    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cscenter{
	background:url(images/rl.png) repeat-y,url(images/rr.png) right bottom repeat-y,linear-gradient(to right, transparent 0px ,transparent 5px,#852e09 5px,#852e09 calc(~"100% - 5px"),transparent calc(~"100% - 5px"));
transform: scale(1.05);
} 

 .skcs{
    transform: scale(1.3);
    background: #852e09;
  }


  .bg-dk{
  	background:#e9e8e6;padding-right: 15px;
  }

.sub-mn{
	display: flex;
    flex-wrap: wrap;
}